.hero {
    background-color: rgba(0,0,0,.5);
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
}

.hero:before {
    content: '';
    background: url('../../assets/Home.png') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 700px;
    margin: auto;
    padding: 1rem;
}
.cloud1 {
    position: absolute;
    top: -300px;
    left: 10px;
    max-width: 1100px;
    mix-blend-mode: overlay;
    opacity: 1;
    overflow: hidden;
    filter: contrast(1.25);
}

.cloud2 {
    position: absolute;
    top: -400px;
    left: 0px;
    max-width: 1100px;
    mix-blend-mode: overlay;
    opacity: 1;
    overflow: hidden;
    filter: contrast(1.25);
}

.cloud3 {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 674px;
    mix-blend-mode: overlay;
    opacity: 1;
    overflow: hidden;
    filter: contrast(1.25);
}
.hero h1 {
    font-size: 3.5rem;
    text-transform: capitalize;
}

.hero .search-text {
    margin: 1.5rem;
    font-size: 1.4rem;
}

.search {
    display: flex;
    background-color: #fff;
    color: #333;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    padding: 4px;
    border-radius: 8px;
}

.search input[type=text] {
    border: transparent;
    background-color: transparent;
    width: 400px;
    font-size: 1.2rem;
    font-family: 'Raleway', sans-serif;
}

.search input[type=text]:focus {
    outline: none;
}

.search label {
    padding: 0 14px 0 8px;
}

.search .radio {
    display: flex;
    align-items: center;
}

.search button {
    background-image: linear-gradient(45deg, #5651e5 0%, #709dff 100%);
    border: 1px solid #5651e5;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
}

.carousel-buttons {
    margin-top: 20px;
  }
  
  .carousel-buttons button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff; /* Change to desired button color */
    color: #fff; /* Change to desired text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .carousel-buttons button:hover {
    background-color: #0056b3; /* Change to desired button hover color */
  }

  .prevSlide {
    position: relative;
    right: -500px;
    color: rgb(205 106 48);
    }

.nextSlide {
    position: relative;
    right: -500px;
    color: rgb(205 106 48);
    }

.carousel-container {
    position: relative;
    max-width: 800px;
    margin: auto;
    overflow: hidden;
  }
  
  .carousel {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .slide {
    min-width: 100%;
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out;
  }
  
  .slide.active {
    opacity: 1;
    visibility: visible;
  }
  
  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .prev {
    left: 10px;
  }
  
  .next {
    right: 10px;
  }
  
  @media screen and (max-width: 940px) {
    .hero .content {
        max-width: 600px !important;
    }
    .carousel-container {
        max-width: 600px !important;
    }
    .hero {
        height: 70vh;
    }

    .hero h1 {
        font-size: 2.5rem;
    }

    .search input[type=text] {
        width: 100%;
    }
    .cloud1,
    .cloud2,
    .cloud3 {
        display: none; /* Hide clouds on smaller screens */
    }
    .carousel {
        display: block !important;
        position: relative;
        top: 100px;
    }
    .carousel-arrows {
        display: none;
    }
}