.certificate {
    background-color: white;
    margin: auto;
    padding: 3rem 1rem 0 1rem;
    width: 100%;
    margin-top: 2rem;
}

.certificate-heading{
    color: rgb(205 106 48);
}

.certificate-text{
    text-transform: capitalize;
}
.certificateiso{
    width: 250px;
}

.certificateiso2{
    width: 250px;
}

.certification-heading{
    color: black;
}

.policy-heading{
    color: black;
}

.isoname{
    color: black;
}