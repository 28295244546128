.contact {
    background-color: rgba(255, 255, 255, 1);
    margin: auto;
    padding: 3rem 1rem 0 1rem;
    width: 100%;
    position: relative;
    top: 85px;
}

.contact .contact-container {
    width: 1040px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.contact-heading {
    text-transform: uppercase;
}

.contactImg {
    width: 500px;
}

.map-text {
    display: flex;
    text-align: center;
    grid-gap: 20px;
}

.phone-text {
    display: flex;
    grid-gap: 20px;
}

.mapimg {
    width: 40px;
    height: 40px;
}

.phone {
    width: 40px;
    height: 40px;
}

.contact2 {
    display: flex;
    justify-content: end;
}

.contactimg2 {
    width: 350px;
    display: flex;
}

.white-rectangle {
    position: relative;
    width: 500px;
    height: 50px;
    background-color: white;
    border: 1px solid rgba(135, 135, 135, 1);
    border-radius: 10px;
}

.white-rectangle-2 {
    position: relative;
    width: 320px;
    height: 50px;
    background-color: white;
    border: 1px solid rgba(135, 135, 135, 1);
    border-radius: 10px;
}

.wr2 {
    display: flex;
    justify-content: end;
    position: relative;
    left: -20px;
}

.name {
    font-size: 15px;
    font-weight: bold;
    color: rgba(128, 128, 128, 1);
    display: flex;
    padding-left: 20px;
    padding-top: 10px;
}

.contactlogo {
    width: 500px;
}

@media screen and (max-width: 940px) {
    .contact .contact-container {
        width: 100%;
        grid-template-columns: 1fr;
    }

    .wr2 {
        justify-content: start;
    }

    .contact2 {
        justify-content: start;
    }

    .contact-txt {
        margin-bottom: 20px;
        /* Add spacing between text and image */
    }

    .map-text,
    .phone-text {
        font-size: 12px;
        /* Decrease font size for better fit on smaller screens */
    }

    .contact-logo {
        margin-top: 40px;
        /* Increase top margin for better spacing */
    }

    .white-rectangle {
        width: 100% !important;
    }

    .contactImg {
        width: 100%;
    }
}