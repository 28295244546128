.carouselcontainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.slick-prev,
.slick-next {
  font-size: 24px;
}

.slick-prev {
  left: -40px;
}

.slick-next {
  right: -40px;
}

.slick-prev:before, .slick-next:before{
  font-family: 'slick';
  position: relative;
  font-size: 36px;
  line-height: 1;
  opacity: .75;
  color: rgb(205 106 48);
}

.slick-prev {
  left: -21px;
  z-index: 999;
}

.slick-next {
  right: 21px;
}

/* Add any additional styling for your slides if needed */
.carouselbox {
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 450px;
}

.carousel-heading {
  font-size: 20px;
  color: rgba(45, 45, 45, 1);
}

.carousel-text {
  font-size: 15px;
  align-items: center;
  display: flex;
}

.carousel-img {
  max-width: 100%; /* Ensure the image doesn't exceed the container width */
  max-width: 100%; /* Ensure the image doesn't exceed the container width */
  justify-self: end; /* Align the image to the end (right side) */
  height: 400px;
  mix-blend-mode: luminosity;
  opacity: 1;
  overflow: hidden;
  filter: contrast(1.25);
}

.maincarousel {
  padding: 35px;
}

@media screen and (max-width: 940px) {
  .carouselbox {
    grid-template-columns: 1fr;
    height: 100%;
}
}