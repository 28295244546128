.navbar {
  position: absolute;
  width: 100%;
  height: 90px;
  top: 0;
  left: 0;
  background: #e1e1e10a;
  z-index: 99;
}

.navbar .container {
  height: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 1rem;
}

.navbar span {
  color: #5651e5;
}

.navbar .nav-menu {
  display: flex;
}

.navbar li {
  padding: 1rem;
}

.navbar .icon {
  font-size: 2rem;
}

.logo {
  width: 85px;
  padding-top: 15px;
}

.logotext {
  width: 125px;
  height: 90px;
  position: relative;
  top: 10px;
}

.toggle-btn {
  color: black;
}

.cloud1 {
  position: absolute;
  left: -400px;
  top: -540px;
}

.sidebar {
  width: 100%;
  background-image: url('../../assets/cloud3.png'),
    url('../../assets/cloud1.png'),
    url('../../assets/cloud4.png'),
    url('../../assets/cloud5.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  color: black;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}

.sidebar.open {
  transform: translateX(0);
}

.content {
  flex: 1;
  padding: 20px;
  transition: margin-left 0.3s ease;
  margin-left: 0;
}

.content.open {
  margin-left: 250px;
  /* Same width as sidebar */
}

button {
  margin: 20px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.toggle-btn.hidden {
  display: none;
}

.nav-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Two equal columns */
  height: 100vh;
  /* Full viewport height */
}

.nav-text {
  justify-content: center;
  display: flex;
  position: relative;
  top: 20%;
}

.sidebarrec1 {
  animation: sidebarrec1 2s infinite;
}

.sidebarrec1 {
  position: relative;
  top: -270px;
  left: 60px;
}

.sidebarrec1 {
  width: 150px;
  height: 350px;
  border: 1px solid #000;
  /* Example border, adjust as needed */
  overflow: hidden;
  /* Ensure the image doesn't overflow the rectangle */
}

.sidebarrec1 {
  position: relative;
  top: 200px;
  /* left: 60px; */
}

.sidebarrec1 img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.sidebarrec2 {
  animation: sidebarrec2 2s infinite;
}

.sidebarrec2 {
  position: relative;
  top: -210px;
  left: 236px;
}

.sidebarrec2 {
  width: 150px;
  height: 350px;
  border: 1px solid #000;
  /* Example border, adjust as needed */
  overflow: hidden;
  /* Ensure the image doesn't overflow the rectangle */
}

.sidebarrec2 img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.sidebarrec3 {
  animation: sidebarrec3 2s infinite;
}

.sidebarrec3 {
  position: relative;
  top: -500px;
  left: 411px;
}

.sidebarrec3 {
  width: 150px;
  height: 350px;
  border: 1px solid #000;
  /* Example border, adjust as needed */
  overflow: hidden;
  /* Ensure the image doesn't overflow the rectangle */
}

.sidebarrec3 img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.sidebarrec1 {
  animation: sidebarrec1 4s infinite;
}

@keyframes sidebarrec1 {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.sidebarrec2 {
  animation: sidebarrec2 3s infinite;
}

@keyframes sidebarrec2 {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.sidebarrec3 {
  animation: sidebarrec3 3.5s infinite;
}

@keyframes sidebarrec3 {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.logotxt {
  position: relative;
  left: 50px;
}

@media screen and (max-width:940px) {
  .hamburger {
    display: block;
  }

  .navbar .nav-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 90px;
    right: -100%;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, .98);
    transition: 0.4s ease-in-out;
    z-index: 10;
  }

  .navbar .active {
    right: 0;
  }

  .navbar .nav-menu li a {
    font-size: 2rem;
  }

  .nav-image {
    display: none;
  }

  .close-btn {
    position: relative !important;
    top: 0px !important;
    right: 0px !important;
  }

  .nav-container {
    grid-template-columns: 1fr;
}
}