.vision {
  background-color: white;
  margin: auto;
  padding: 3rem 1rem 0 1rem;
  width: 100%;
  margin-top: 2rem;
}

.vision .container {
  width: 1240px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.vision-column {
  height: 600px;
}

.visionline {
  display: flex;
  width: 250px;
  z-index: 1;
  position: relative;
}

.visionrocket {
  z-index: 9;
  position: relative;
  top: -200px;
  left: 100px;
}

.visionmainimg {
  position: relative;
  top: -180px;
}

.rectangle1 {
  position: relative;
  top: -270px;
  left: 60px;
}

.rectangle2 {
  position: relative;
  top: -160px;
  left: 60px;
}

.rectangle1 {
  animation: rectangle1 2s infinite;
}

@keyframes rectangle1 {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.rectangle2 {
  animation: rectangle2 2s infinite;
}

@keyframes rectangle2 {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.orbit {
  height: 350px;
  width: 350px;
  border-radius: 50%;
  position: relative;
  margin: auto;
  left: -60px;
  top: -780px;
  animation: spin 5s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(90deg);
  }
}

.rocket {
  background-color: black;
  height: 50px;
  width: 25px;
  border-radius: 50% 50% 0 0;
  position: relative;
  left: -12px;
  top: 150px;
}

.rocket:before {
  position: absolute;
  content: "";
  background-color: #39beff;
  height: 20px;
  width: 55px;
  border-radius: 50% 50% 0 0;
  right: -15px;
  bottom: 0;
}

.window {
  height: 10px;
  width: 10px;
  background-color: #151845;
  border: 2px solid #b8d2ec;
  border-radius: 50%;
  position: relative;
  top: 110px;
  left: -3px;
}

.vision-heading1 {
  text-transform: capitalize;
  font-size: 20px;
  color: rgba(45, 45, 45, 1);
}

.vision-heading2 {
  text-transform: capitalize;
  font-size: 55px;
  color: rgb(205 106 48);
}

.vision-description {
  text-transform: capitalize;
  font-size: 15px;
}

.vision-description {
  /* Add necessary styles */
  word-wrap: break-word;
  /* Allow long words to be broken and wrap onto the next line */
}

@media screen and (max-width: 940px) {
  .vision .container {
    width: 100%;
    max-width: 600px;
    grid-template-columns: 1fr;
    /* Change to single column layout for smaller screens */
    padding: 0 20px;
    /* Optional: Add some padding to the sides for better readability */
  }

  .vision-column {
    display: none;
  }

  .vision-heading2 {
    font-size: 1.5rem;
    /* Adjust heading size for smaller screens */
  }

  .vision-description p {
    font-size: 14px;
    /* Adjust paragraph font size for smaller screens */
  }
}