.projects-heading{
  color: rgb(205 106 48);
}

.dubai1{    
    width: 100%;
}
.corevalue-box {
    position: relative;
  }
  .corevalue-box2 {
    position: relative;
  }
  .corevalue-box3 {
    position: relative;
  }
  .corevalue-box4 {
    position: relative;
  }

  .projectheading {
    color: black;
    font-size: 15px;
}
  
  .box {
    width: 200px;
    height: 95px;
    background-color: rgba(0, 68, 106, 0.75);
    color: #fff;
    text-align: center;
    line-height: 60px;
    transition: width 1.3s ease, left 1.3s ease;
    position: absolute;
    top: 370px;
    left: calc(100% - 200px);
  }

  .box2 {
    width: 200px;
    height: 95px;
    background-color: rgba(0, 68, 106, 0.75);
    color: #fff;
    text-align: center;
    line-height: 60px;
    transition: width 1.3s ease, left 1.3s ease;
    position: absolute;
    top: 480px;
    left: calc(100% - 200px);
  }

  .box3 {
    width: 200px;
    height: 95px;
    background-color: rgba(0, 68, 106, 0.75);
    color: #fff;
    text-align: center;
    line-height: 60px;
    transition: width 1.3s ease, left 1.3s ease;
    position: absolute;
    top: 590px;
    left: calc(100% - 200px);
  }

  .box4 {
    width: 200px;
    height: 95px;
    background-color: rgba(0, 68, 106, 0.75);
    color: #fff;
    text-align: center;
    line-height: 60px;
    transition: width 1.3s ease, left 1.3s ease;
    position: absolute;
    top: 700px;
    left: calc(100% - 200px);
  }
  
  .hover-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease; /* Transition opacity for fade effect */
  }

  .hover-text2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease; /* Transition opacity for fade effect */
  }

  .hover-text3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease; /* Transition opacity for fade effect */
  }

  .hover-text4 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease; /* Transition opacity for fade effect */
  }

  
  .box:hover {
    width: 350px;
      left: calc(100% - 350px); /* Moves the box towards the left */
  }

  .box2:hover {
    width: 350px;
      left: calc(100% - 350px); /* Moves the box towards the left */
  }

  .box3:hover {
    width: 350px;
      left: calc(100% - 350px); /* Moves the box towards the left */
  }

  .box4:hover {
    width: 350px;
      left: calc(100% - 350px); /* Moves the box towards the left */
  }
  
  .additional-text {
    display: none;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  .additional-text2 {
    display: none;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  .additional-text3 {
    display: none;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  .additional-text4 {
    display: none;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .box:hover .additional-text {
    display: block;
  }

  .box2:hover .additional-text2 {
    display: block;
  }

  .box3:hover .additional-text3 {
    display: block;
  }

  .box4:hover .additional-text4 {
    display: block;
  }
  .corevalues1{    
    position: absolute;
    left: 100px;
    top: 3350px;
    height: 100px;
}

.corevalue-container{
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 20px;
}
.map{
  width: 700px;
  display: flex;
  justify-content: end;
  height: 400px;
}
.leaflet-container {
  height: 400px;
  width: 600px;
  max-width: 100%;
  max-height: 100%;
}
.project{ 
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 10px;
  overflow-y: scroll;
  height: 400px;
}
.project div img{ 
  width: 100%;
  height: 100px;
}
.project div img:hover{ 
  opacity: 0.5;
}

.leaflet-popup-content-wrapper {
  width: 215px;
}

@media screen and (max-width: 940px) {
  .corevalue-container {
    grid-template-columns: 1fr;
  }

  .project {
    grid-template-columns: 1fr;
    height: 400px;
}
.project div img {
  height: 250px;
}
.map {
  display: none;
}
}