@import url('https://fonts.googleapis.com/css2?family=Judson:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #5bb462;
  --primary-dark: #4f9c55;
  --secondary-color: #ffea00;
  --secondary-dark:#e3d002;
  --overlay: rgba(0,0,0,.7);
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

body {
  margin: 0;
  font-family: Judson;
  line-height: 30.2px;
  letter-spacing: 3px;
  color: rgb(80 77 77 / 70%);
}

.container {
  max-width: 1240px;
  margin: auto;
}

.btn {
  color: #fff;
  padding: 12px 24px;
  background-image: linear-gradient(45deg, #5651e5 0%, #709dff 100%);
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  transition: .4s;
  cursor: pointer;
}

.btn:ative {
  transform: translate(4px);
  box-shadow: 0px 0px 1px rgba(0,0,0,0,.2);
  border-bottom:none;
}

.inner{
  white-space: nowrap;
  transition: transform .3s;
}

.carousel-item{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 490px;
  background-color: white;
}

.carousel-item-text{
  font-size: 1.15rem;
  margin: 40px 0 40px 0;
  padding: 0 20px 0 20px;
  white-space: normal;
  color: #000;
}

.carousel-img{
  width: 450px;
  padding: 0 20px 0 20px;
}

@media screen and (max-width: 940px) {
  .container {
    max-width: 600px;
  }
}