.about {
    background-color: white;
    margin: auto;
    padding: 3rem 1rem 0 1rem;
    width: 100%;
}

.about .container {
    width: 1240px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.about-heading1 {
    font-size: 20px;
    color: rgba(45, 45, 45, 1);
}

.about-heading2 {
    font-size: 46px;
    color: rgb(205 106 48);
}

.about .col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 3rem;
}

.about .bulb-text{
    margin-top: 1rem;
}

.about .bulb-column{
    margin-top: 1rem;
}

.about .bulb-heading{
    padding: 0 100px;
    color: rgba(45, 45, 45, 1);
}

.bulb-image {
    display: flex;
    justify-content: center;
}

.Lightmodearrow{
    position: relative;
    left: 280px;
}

.about-text{
    font-size: 15px;
}

.bulb {
    width: 300px;
}

  @keyframes Lightmodearrow {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  .Lightmodearrow {
    animation: Lightmodearrow 2s infinite;
    width: 100px;
  }

  .add-border{    
    padding: 25px;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
  }

  .bulb-text-header {
    transition: transform 0.5s ease-in;
  }
  
  .slide-down {
    transform: translateY(90%);
  }

  .light-theme .bulb-heading{
    color:white;
  }

  .light-theme .about-text{
    color:white;
  }

 .light-theme .about-heading1{
    color:white;
  }

  .light-theme .about-heading2{
    color:white;
  }

  .light-theme .about-dark{
    background-color:#143A4B;
  }
@media screen and (max-width:940px) {
    .about .container {
        grid-template-columns: 1fr;
    }

    .about .container {
        width: 100%;
    }

    .about .container img {
        padding: 0rem;
        width: 200px;
    }

    .about .container .col-2 {
        padding: 0;
    }

    .about button {
        margin-bottom: 1rem;
    }

    .Lightmodearrow {
      display: none;
  }
  .bulb-image {
    justify-content: start;
}
}