.clients-heading{
  text-transform: capitalize;
  color: rgb(205 106 48);
}

.col-lg-12{
    flex: 0 0 auto;
    width: 100%;
}

.row{
    display: flex;
    flex-wrap: wrap;
}

.partners {
    background: transparent;
    margin-right: 14px;
    margin-bottom: 20px;
    border-radius: 8px 8px 8px 8px;
    padding: 0px;
    /* margin-left: 19px; */
}

.col-lg-2 {
    flex: 0 0 auto;
    width: 16.825%;
    display: flex;
    align-items: center;
}

.clientimg {
    width: 200px;
    height: 100px;
    margin: 10px;
    cursor: pointer;
    border: 2px solid transparent; /* Add a transparent border */
    transition: border 0.3s ease; /* Add transition effect for border */
    mix-blend-mode: luminosity;
    opacity: 1;
    overflow: hidden;
    -webkit-filter: contrast(1.25);
    filter: contrast(1.25);
  }

.client-image:hover {
    transform: scale(1.1);
  }
  
  .active .clientimg {
    display: flex;
    position: relative;
    /* border: 2px solid #00f; Change the border color for the active state */
    background-color: grey;
    z-index: 2; /* Ensure the active image is above client-info */
  }
  
  .client-info {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    left: 0;
    top: 2700px;
    width: 100%;
    height: 100vh;
    color: white;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    display: none;
    white-space: normal; /* Add this line */
    z-index: 0; /* Ensure client-info is below the active image */
    transition: opacity 0.3s ease;
  }

  .clientinfotext{
    display: grid;
    justify-content: center;
  }

  .active .client-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1; /* Bring the client-info above other images */
  }
  
  .client-container:hover .client-info {
    opacity: 1;
  }
  
  .active .client-container .client-info {
    background-color: red; /* Adjust background color for the active image */
  }
  
  .client4{    
    height: 50px;
  }

  .client10{    
    height: 50px;
  }

  .client15{    
    height: 50px;
  }

  @media screen and (max-width: 940px) {
    .col-lg-2 {
      width: 40%;
  }
  }